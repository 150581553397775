/* eslint-disable prettier/prettier */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-console */
/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable sonarjs/no-collapsible-if */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable sonarjs/no-identical-functions */
import React, { useEffect, useState, useContext } from 'react';
import moment from 'moment';
import InfiniteScroll from 'react-infinite-scroll-component';
import { ReactComponent as NoRecord } from '../../assets/images/No-records.svg'
import { ReactComponent as RacFilter } from '../../assets/images/RacSort.svg';
import { ReactComponent as AlertIcon } from '../../assets/images/no-records-found.svg';
import { useHistory } from 'react-router-dom';
import { DashboardContext } from '../../context/DashboardContext';
import { ReactComponent as Expandicon } from '../../assets/images/fullscreen-mode.svg';
import { ReactComponent as MarkAsCompleteIcon } from '../../assets/images/mark-as-complete.svg';
import { getStoreActionsItem, getPrintEditPricetagGrid, manageStoreActionItems, GetInventoryInformation, GenerateReceipt } from '../../api/user';
import { ContainerContext } from '../../app/App';
import { CustomPropInterface } from '../../index';
import { getmenuandstore } from '../../api/user';
import { getDapEvent } from '../../api/user'
import OutsideClickHandler from 'react-outside-click-handler'
import {
  makeStyles,
  Card,
  Grid,
  CardContent,
  Typography,
  RACSelect,
  RACModalCard,
  CircularProgress,
  RACCOLOR,
  RACButton
} from '@rentacenter/racstrap';
import { printHTML } from '../shared/print';
import html2pdf from 'html2pdf.js';
import { validateObject } from '../shared/validateObject';
//UI
const useClasses = makeStyles((theme: any) => ({
  p3: {
    padding: theme.typography.pxToRem(16),
  },
  masterLoader: {
    position: "fixed",
    backgroundColor: `${RACCOLOR.WASHED_BLACK}`,
    width: "100%",
    height: "100%",
    top: 0,
    left: 0,
    opacity: 0.6,
    zIndex: 1050,
    textAlign: "center",
    margin: 0,
  },
  Loader: {
    display: "block",
    position: "fixed",
    zIndex: 1051,
    top: "50%",
    right: "50%",
  },
  RACPOPMsg: {
    fontFamily: "OpenSans-semibold",
    fontSize: theme.typography.pxToRem(17),
    letterSpacing: 0,
  },
  justifyContentCenter: {
    alignItems: "center",
    marginTop: "10%",
    justifyContent: "center",
  },
  foc: {
    "&:focus": {
      borderColor: "#80bdff",
      boxShadow: "1px 1px 6px 3px #80bdff",
    },
  },
  disablePhonenumberInput: {
    backgroundColor: '#d3d3d3 !important',
  },
  mt3: {
    marginTop: theme.typography.pxToRem(16),
  },
  me2: {
    marginRight: theme.typography.pxToRem(8),
  },
  spacerMT4: {
    marginTop: theme.typography.pxToRem(24),
  },
  formControlPh: {
    display: 'block',
    width: '100%',
    padding: '0.155rem 0.66rem',
    fontSize: '1rem',
    lineHeight: '1.5',
    color: '#212529',
    backgroundColor: '#fff',

    border: '1px solid #ced4da',

    appearance: 'none',
    borderRadius: '0.35rem',
    whiteSpace: 'nowrap',

    '&:focus-visible': {
      boxShadow: '0 0 0 0.25rem rgb(0 123 255 / 25%)',
      borderColor: '#80bdff',
      borderRadius: '8px',
      outline: 'none',
    },
  },
  hide: {
    display: 'none',
  },
  textcenterNorecords: {
    textAlign: 'center',
    marginTop: theme.typography.pxToRem(80),
    width: '100%',
    fontFamily: 'OpenSans-regular',
    fontSize: theme.typography.pxToRem(14),
  },
  textEnd: {
    textAlign: 'right',
  },
  px3: {
    paddingRight: theme.typography.pxToRem(16),
    paddingLeft: theme.typography.pxToRem(16),
  },
  mx1: {
    marginRight: theme.typography.pxToRem(4),
    marginLeft: theme.typography.pxToRem(4),
  },
  popupText: {
    fontFamily: 'OpenSans-semibold',
    fontSize: '17px',
  },
  pb4: {
    paddingBottom: theme.typography.pxToRem(24),
  },
  txtLabelStyle: {
    fontFamily: 'OpenSans-semibold',
  },
  spaceTitle: {
    marginBottom: theme.typography.pxToRem(0),
    paddingBottom: theme.typography.pxToRem(0),
  },
  mb3: {
    marginBottom: theme.typography.pxToRem(16),
  },
  formLabel: {
    marginBottom: '0.2rem',
    color: '#111111',
    fontFamily: 'OpenSans-bold',
    fontSize: theme.typography.pxToRem(14),
  },
  formCheck: {
    display: ' block',
    minHeight: '1.5rem',
    paddingLeft: '1.5em',
    marginBottom: ' 0.125rem',
  },
  ms2: {
    marginLeft: theme.typography.pxToRem(8),
  },
  w100: {
    width: '100%',
  },
  circularLoader: {
    marginTop: theme.typography.pxToRem(75),
  },
  textCenter: {
    textAlign: 'center',
  },
  fixedheadertableonline1: {
    overflowY: 'auto',
    maxHeight: "12.125rem",
  },
  RACexpandIcon: {
    width: theme.typography.pxToRem(22),
    height: theme.typography.pxToRem(22),
    right: theme.typography.pxToRem(4),
    top: theme.typography.pxToRem(5),
    position: 'absolute',
    cursor: 'pointer',
    display: 'none',
  },
  filterBtn: {
    padding: '10px',
    borderColor: '#D9E2ED !important',
    minWidth: '50px',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  mr2: {
    marginRight: theme.typography.pxToRem(8),
  },
  positionRelative: {
    position: 'relative',
  },
  cardBody: {
    flex: "1 1 auto",
    padding: "1rem 1rem",
    paddingBottom: "10px !important",
  },
  card: {
    borderRadius: '16px!important',
    boxShadow: '0 1px 4px 0 rgba(0,0,0,.074)!important',
    width: '100%',
  },
  statusDropdownWidthForMultiSelect: {
    // float: 'left',
    width: '100% !important',
    // padding: '3px 4px',
    // marginBottom: '20px',
    marginRight: '11px',
  },
  mb2: {
    marginbottom: '0.5rem !important'
  },
  transactionSpacing: {
    alignItems: 'center',
    marginBottom: theme.typography.pxToRem(15),
    padding: '5px',
    marginTop: '2px',
    '&:hover': {
      backgroundColor: '#f1f1f1',
    },
    '&:firstchild': {
      display: 'block'
    }
  },
  listgroupitem: {
    position: 'relative',
    display: 'block',
    // padding: '0.5rem 1rem',
    color: '#212529',
    textDecoration: 'none',
    backgroundColor: '#fff',
    cursor: 'pointer',
    marginBottom: theme.typography.pxToRem(8)
  },
  mt4: {
    marginTop: theme.typography.pxToRem(24),
  },
  positionAbsolute: {
    position: 'absolute',
  },
  RACcardHeightModal: {
    marginTop: '3px !important',
    paddingTop: '0px !important',
    paddingBottom: '0px !important',
    paddingLeft: theme.typography.pxToRem(15),
    paddingRight: theme.typography.pxToRem(15),
  },
  racGlobalSearchShow: {
    display: 'block',
    zIndex: 1,
    width: '235px',
    height: '67px',
    right: '0',
    left: 'auto !important',
    position: 'absolute',
  },
  racGlobalSearchHide: {
    display: 'none'
  },
  title: {
    color: '#212529',
    fontFamily: 'OpenSans-bold!important',
    fontSize: '16px!important',
    marginBottom: '10px!imporatant',
  },
  titleColor: {
    color: 'gray',
  },
  RACText: {
    marginTop: theme.typography.pxToRem(6),
    fontSize: theme.typography.pxToRem(14),
    width: '100% !important',
    // textAlign: 'justify',
  },
  componentRight: {
    justifyContent: 'flex-end',
    alignItems: 'center',
    display: 'flex',
  },
  infoTextStyle: {
    color: 'gray',
    fontSize: theme.typography.pxToRem(13),
    textAlign: 'center',
  },
  row: {
    display: "flex",
    flexWrap: "wrap",
    marginTop: "0",
    marginRight: "-0.75rem",
  },
  filterDropDown: {
    zIndex: 1,
    width: '235px',
    height: '67px',
    right: '0',
    left: 'auto !important',
  },
  floatLeft: {
    float: 'left',
  },
  spaceNowrap: {
    whiteSpace: 'nowrap',
  },
  mandatoryfield: {
    color: `${RACCOLOR.MAXIMUM_RED}`,
  },
  ms1: {
    marginLeft: theme.typography.pxToRem(4),
  },
  py4: {
    paddingTop: theme.typography.pxToRem(24),
    paddingBottom: theme.typography.pxToRem(24),
  },
  ms5: {
    marginLeft: theme.typography.pxToRem(48),
  },
  mt2: {
    marginTop: theme.typography.pxToRem(8),
  },
  RACDate: {
    fontSize: theme.typography.pxToRem(14),
    fontFamily: 'OpenSans-semibold',
    paddingTop: theme.typography.pxToRem(0),
    paddingRight: theme.typography.pxToRem(15),
    paddingBottom: theme.typography.pxToRem(5),
    paddingLeft: theme.typography.pxToRem(15),
    float: 'right',
    color: '#212529'
  },
  my5: {
    marginTop: theme.typography.pxToRem(48),
    marginBottom: theme.typography.pxToRem(48),
  },
  mb5: {
    marginBottom: theme.typography.pxToRem(48),
  },
  pt2: {
    paddingTop: theme.typography.pxToRem(8),
  },
  px2: {
    paddingRight: theme.typography.pxToRem(8),
    paddingLeft: theme.typography.pxToRem(8),
  },
  componentSpaceBetween: {
    justifyContent: 'space-between',
    alignItems: 'center',
    display: 'flex',
  },
  RACcardsales: {
    borderRadius: theme.typography.pxToRem(16),
    boxShadow: '0px 1px 4px 0px  #0000013',
    position: 'relative',
    maxHeight: theme.typography.pxToRem(303),

    '&:hover': {
      '& $RACexpandIcon': {
        display: 'block',
      },
    },
  },
  alerticon: {
    paddingRight: theme.typography.pxToRem(10),
    paddingTop: theme.typography.pxToRem(8),
  },
  RACexpandIconclose: {
    width: theme.typography.pxToRem(22),
    height: theme.typography.pxToRem(22),
    right: theme.typography.pxToRem(4),
    top: theme.typography.pxToRem(5),
    position: 'absolute',
    cursor: 'pointer',
  },
  multiSelect: {
    //overflow: 'auto',
    height: '250px',
    // "& div": {
    // width: "100%",
    // marginTop: "10px",
    // marginRight: "18px",
    // "& div": {
    //   "& div": {
    //     marginTop: '-4px',
    //     "& div": {
    //       marginTop: '7px'
    //     }
    //   }
    // }
    // },
  },
  RACPaddingBottom: {
    paddingBottom: '4px !important'
  },
  cardHeight: {
    height: theme.typography.pxToRem(271),
  },
}));
export default function StoreActionItem() {
  const storeNumber = window.sessionStorage.getItem('storeNumber');
  const { setActionEvent } = useContext(DashboardContext); //xx
  const classes = useClasses();
  const history = useHistory();
  useEffect(() => {
    pagelaod();
  }, [])
  const options = { advancedFilter: [{ label: 'All', value: "ALL" }, { label: 'Agreement', value: "AGR" }, { label: 'Inventory', value: "INV" }, { label: 'Customer', value: "CUS" }] }
  const modalCardRadius = '25px !important';
  const [storeFilterDetails, setStoreFilterDetails] = useState<any>({
    filterDetails: []
  })
  const [hasMore, sethasMore] = useState(true);
  const [fetchDataLoader, setfetchDataLoader] = useState(false);
  const [NomoreDataOtherReq, setNomoreDataOtherReq] = useState(false);
  const [offset, setoffset] = useState(1);
  const [hideHelper, setHideHelper] = useState('');
  const [amLoader, setAMLoader] = useState(false);
  const [loader, setloader] = useState(false);
  const [expandStore, setExpandStore] = React.useState(false);
  const [storeActionRes, setStoreActionRes] = useState<any>({ originalVal: [], AlteredVal: [] })
  const [openFilter, setOpenFilter] = useState(false)
  const [autoScroll, setAutoScroll] = useState<any>();
  const [Status, setStatus] = useState(false);
  const [expandIcon, setExpandIcon] = useState(false);
  const [noRecord, setNoRecord] = useState<any>();
  const [role, setRole] = useState<any>('');
  const [openMarkAsCompletePopup, SetMarkAsCompletePopup] = useState(false);
  const [openInventoryTransferOutPopup, SetInventoryTransferOutPopup] = useState(false);
  const [openInventoryTransferInPopup, SetInventoryTransferInPopup] = useState(false);
  const [storeActionItemId, SetStoreActionItemId] = useState('');
  const [errorMessagePopupOpen, seterrorMessagePopupOpen] = useState(false);
  const [manageErrMessage, setmanageErrMessage] = useState('');
  const [somethingWentWrongOpen, setSomethingWentWrongOpen] = useState(false);
  const [expandAPICallLoader, setExpandAPICallLoader] = useState<any>(false);
  const [hasInvPriceTag, setHasInvPriceTag] = useState<any>([]);
  const containerData = useContext(ContainerContext) as CustomPropInterface;
  const el: any = document.getElementsByName("filter")
  const [inventoryInformations, setinventoryInformations] = useState<any>(
    {
      inventoryHeaderObj: {},
      inventoryInformationObj: {},
      currentAgreementObj: {},
      inventoryTransferObj: {}
    }
  )
  console.log("filter", el)

  const sortStoreActionItemIds = (storeActionItem: any) => {
    if (!storeActionItem || storeActionItem.length == 0) return storeActionItem
    return storeActionItem.sort((prev: any, next: any) => next.storeActionItemId - prev.storeActionItemId)
  }
  const doFilteroutStoreAction =(myrole: any, response:any): any=>{
    if(!myrole) return response;

    if (myrole !== 'CAR') return response;

    if(response?.data?.storeActionDetails?.length === 0 )return response;

    response.data.storeActionDetails = response.data.storeActionDetails.filter(item=>item.storeActionType!='DSS');;
    return response;
  };


  const pagelaod = async () => {
    setAMLoader(false)
    try {
      const storeNumber = window.sessionStorage.getItem('storeNumber');
      const payload: any = {
        storeNum: storeNumber,
        limit: 10,
        offset: offset
      }
      const storeActionItemResponse: any = await Promise.all([
        getStoreActionsItem(payload),
        getPrintEditPricetagGrid(storeNumber),
      ])

      const myrole = await getRole();
      
      storeActionItemResponse[0] = doFilteroutStoreAction(myrole, storeActionItemResponse[0]);

      if (storeActionItemResponse[1]?.data?.response?.length > 0 || storeActionItemResponse[0]?.data?.storeActionDetails?.length > 0) {
        setExpandIcon(true)
      }
      if (storeActionItemResponse[0]?.data == "" && storeActionItemResponse[1]?.data?.response?.data?.response?.length == 0) {
        setNoRecord(true);
      }
      else {
        setNoRecord(false);
      }
      setAMLoader(false)
      console.log("response", storeActionItemResponse)
      const alteredVal: any = []
      if (storeActionItemResponse[1]?.data?.response?.length > 0) {
        const val: any = {
          storeActionItemId: '767676',
          storeId: '787',
          storeActionType: "1",
          agreementId: "78687",
          storeActionCategory: "INV",
          description: "Print Tag(s) in Print Queue ",
          customerId: '1',
          inventoryId: "8798",
          redirectUrl: "/inventory/printeditpricetag",
          createdDate: moment().format('MM/DD/YYYY')
        }
        alteredVal.push(val)
        setHasInvPriceTag(alteredVal)
        //  const Altered:any = storeActionItemResponse[0].data.storeActionDetails.push(val)
        console.log("Altered", alteredVal)
        // {...storeActionDetails.originalVal,...val}
      }
      alteredVal.push(sortStoreActionItemIds(storeActionItemResponse[0]?.data?.storeActionDetails))
      setStoreActionRes({ ...storeActionRes, originalVal: alteredVal.flat(Infinity), AlteredVal: alteredVal.flat(Infinity) })
      if (alteredVal[0]?.length < 9) {
        setAutoScroll(true)
      }
      else {
        setAutoScroll(false)
      }

      if (storeActionItemResponse[0]?.status !== 200 || storeActionItemResponse[1].status !== 200) {
        setStatus(true);
      }
      else {
        setStatus(false);
      }
    }
    catch (e) {
      console.log("error", e);
      setAMLoader(true)
      setStatus(true)
      setExpandIcon(false)
    }
  }
  { console.log("storeActionRes", storeActionRes?.originalVal) }

  const getRole = async()=>{
    if(role) return role;
    let roleResult = '';
    if (containerData !== undefined && containerData.GetRole()) {
      roleResult = containerData.GetRole();
    }
    else{
      const coWorkerDetailsObj: any =  await getmenuandstore();
      roleResult = coWorkerDetailsObj?.data?.coworkerProfile?.role;
    }
    setRole(roleResult);
    return roleResult;
  };

  useEffect(() => {
    if (expandStore && hasMore) {
      const loadStoreActionItem = async () => {
        try {
          const priceTagItem = hasInvPriceTag.length > 0 && hasInvPriceTag[0]?.storeActionCategory == 'INV' ? [hasInvPriceTag[0]] : []
          const payload = {
            storeNum: window.sessionStorage.getItem('storeNumber'),
            limit: 400,
            offset: 1
          }
          setExpandAPICallLoader(true)
          let storeActionItemRes = await getStoreActionsItem(payload);
          const myrole = await getRole();
          storeActionItemRes = doFilteroutStoreAction(myrole, storeActionItemRes);

          if (storeActionItemRes?.status == 200 && storeActionItemRes.data && storeActionItemRes.data.storeActionDetails) {
            storeActionItemRes.data.storeActionDetails = sortStoreActionItemIds(storeActionItemRes?.data?.storeActionDetails)
            setStoreActionRes({ originalVal: [...priceTagItem, ...storeActionItemRes?.data?.storeActionDetails], AlteredVal: [...priceTagItem, ...storeActionItemRes?.data?.storeActionDetails] })
            sethasMore(false);
          }
          setExpandAPICallLoader(false)
        } catch (e) {
          setExpandAPICallLoader(false)
        }
      }
      loadStoreActionItem()
    }

  }, [expandStore])
  const storeActionFilter = () => {
    setOpenFilter(true);
  }
  const expandStoreActionItem = () => {
    expandStore === false ? setExpandStore(true) : setExpandStore(false);
  }
  const storeActionFilterClose = () => {
    setOpenFilter(false);
  }
  const handleFilter = (event: any): any => {
    event.stopPropagation()

    // alert("coming");
    setAMLoader(true)
    const AlteredArray: any = [];
    console.log("category", event.target.value)
    const category = event.target.value
    setStoreFilterDetails({ ...storeFilterDetails, filterDetails: category })
    return storeActionRes?.originalVal?.map((el: any) => {
      if (category == "AGR") {
        if (el?.storeActionCategory == "AGR") {
          AlteredArray.push(el)
        }
      }
      if (category == "INV") {
        if (el?.storeActionCategory == "INV") {
          AlteredArray.push(el)
        }
      }
      if (category == "CUS") {
        if (el?.storeActionCategory == "CUS") {
          AlteredArray.push(el)
        }
      }
      if (category == "ALL") {
        AlteredArray.push(el)
      }

      setStoreActionRes({ ...storeActionRes, AlteredVal: AlteredArray })

    })
  }
  const bindCategory = (el: any): any => {
    if (el?.storeActionCategory == "INV") {
      return "Inventory";
    }
    if (el?.storeActionCategory == "AGR") {
      return "Agreement";
    }
    if (el?.storeActionCategory == "CUS") {
      return "Customer";
    }
  }
  const truncateString = (str: string): string => {
    if (str?.length > 49 && str !== undefined && str !== null && str !== '') {
      return (str?.substring(0, 39) + '...').toString();
    }
    return str;
  };
  const truncateStringForPopUp = (str: string): string => {
    if (str?.length > 35 && str !== undefined && str !== null && str !== '') {
      return (str?.substring(0, 25) + '...').toString();
    }
    return str;
  };
  const manageStoreActionItem = async () => {
    try {
      const payloadForManageStoreActionItem: any = {
        storeNumber: sessionStorage.getItem('storeNumber'),
        storeActionItemIds: [storeActionItemId.toString()]
      }
      setloader(true)
      const response = await manageStoreActionItems(payloadForManageStoreActionItem)
      setloader(false);

      if (response.status == 200) {
        setloader(true)
        await pagelaod();
        setloader(false)
        SetMarkAsCompletePopup(false);
      }
      else if (response?.status == 400) {
        seterrorMessagePopupOpen(true);
        setmanageErrMessage(response?.data?.errors[0]?.error);
        SetMarkAsCompletePopup(false);
        setExpandStore(false);
      }
      else {
        SetMarkAsCompletePopup(false);
        setExpandStore(false);
        setSomethingWentWrongOpen(true);
      }
    }
    catch (e: any) {
      setSomethingWentWrongOpen(true);
      SetMarkAsCompletePopup(false);
      setExpandStore(false);
      setloader(false)
    }
  }
  const bindPopUpMessage = (): any => {
    return storeActionRes?.AlteredVal?.map((el: any, index: any) => {
      const CreatedDate: any = moment(el?.createdDate?.split('T')[0]).format('MM/DD/YYYY')
      const shouldShowIcon = el?.storeActionItemId !== '767676';
      return (
        <>
          {el !== undefined && el !== null && el !== '' ?
            <Card style={{
              margin: "0.5%", width: "100%",
              zIndex: 0, textDecoration: 'none', fontSize: 'medium',
              fontWeight: 'bold', color: '#2179FE',
              borderLeft: borderLeftColor(el), display: 'flex',
              position: 'relative',
            }} key={index}>
              <CardContent style={{ width: "100%" }}>
                <a style={{
                  width: "100%",
                  position: 'relative',
                  display: 'block',
                  padding: '0.5rem1rem',
                  color: '#212529',
                  textDecoration: 'none',
                  // backgroundColor: '#F4F7FF',
                  // border: '1px solid rgba(0, 0, 0, 0.125)',
                  // paddingLeft: "5%",
                  cursor: 'pointer',
                  borderRadius: "3px",
                  boxShadow: "0 0.125rem 0.25rem rgb(0 0 0 / 8%) !important",
                }}
                  onClick={() => handleNavigate(el)}
                >
                  <Grid style={{ display: 'flex', flexDirection: 'column', paddingLeft: 0, marginBottom: 0, borderRadius: '0.25rem' }}>
                    <Grid style={{ width: "100%" }}>
                      <Grid style={{ width: "100% important" }}>
                        <Typography title={el?.description} style={{ width: shouldShowIcon ? '80%' : '100%', float: 'left' }}>
                          {truncateStringForPopUp(el?.description)}
                        </Typography>
                        <Typography style={{ width: shouldShowIcon ? '20%' : '0%', float: 'left' }}>
                          {shouldShowIcon && (
                            <MarkAsCompleteIcon title="Mark as completed" style={{ width: '25px', marginLeft: '43px' }}
                              onClick={(event) => { handleIconClick(event, el) }}
                            >
                            </MarkAsCompleteIcon>
                          )}
                        </Typography>
                      </Grid>
                      <Grid>
                        <Grid className={classes.row} style={{ width: "100% important" }}>
                          <Typography style={{ width: "75%", color: bindColorCategory(el) }}>
                            {bindCategory(el)}
                          </Typography>
                          <Typography style={{ color: "#6C86A5", width: "25%" }}>
                            {CreatedDate}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </a>
              </CardContent>
            </Card>
            : null}
        </>
      )
    })
  }
  const handleNavigate = (el: any): any => {
    //  debugger;
    console.log("handleNavigate", el)

    if (el.storeActionType == 'TBINSCOM') {
      const splittedUrl = el.redirectUrl?.split("?")
      history.push({
        pathname: `/receivingpo/purchaseorders`,
        search: `?${splittedUrl[1]}&saiid=${el.storeActionItemId}`
      })
    }
    else if (el.storeActionType == 'DSS') {
      handleDSS(el);
    }
    else if (el.storeActionType == 'EAITF') {
      console.log('inside else if EAITF', el)
      handleTransferOutPopUp(el);
    }
    else if (el.storeActionType == 'EAITT') {
      console.log('inside else if EAITT', el)
      handleTransferInPopUp(el);
    }
    else {
      history.push({
        pathname: `${el?.redirectUrl}`,
        state: { storeActionItemId: el?.storeActionItemId }
      })
    }
  }
  const handleTransferOutPopUp = async (e: any) => {
    setloader(true);
    SetStoreActionItemId(e?.storeActionItemId);
    try{
      const response: any = await GetInventoryInformation(e.inventoryId);
      console.log('response from GetInventoryInformation', response)
      console.log('validateObject(response)', validateObject(response))
      console.log(' validateObject(response.data)', validateObject(response.data))
      setloader(false);

      if (validateObject(response) &&
        response.status == 200 &&
        validateObject(response.data) &&
        ((validateObject(response.data.inventoryHeader)) ||
          validateObject(response.data.inventoryInformation) ||
          validateObject(response.data.currentAgreement) ||
          validateObject(response.data.inventoryTransferInformation)
        )
      ) {
        setinventoryInformations({
          ...inventoryInformations,
          inventoryHeaderObj: response.data.inventoryHeader,
          inventoryInformationObj: response.data.inventoryInformation,
          currentAgreementObj: response.data.currentAgreement,
          inventoryTransferObj: response.data.inventoryTransferInformation
        })
        console.log('inventoryInformations log', inventoryInformations)
      }
      SetInventoryTransferOutPopup(true)
    }
    catch(e){
      SetStoreActionItemId('');
      setloader(false);
      console.log(e)
    }
  }
  const handleTransferInPopUp = async (e: any) => {
    setloader(true);
    SetStoreActionItemId(e?.storeActionItemId);
    try{
      const response: any = await GetInventoryInformation(e.inventoryId);
      console.log('response from GetInventoryInformation', response)
      console.log('validateObject(response)', validateObject(response))
      console.log(' validateObject(response.data)', validateObject(response.data))
      setloader(false);

      if (validateObject(response) &&
        response.status == 200 &&
        validateObject(response.data) &&
        ((validateObject(response.data.inventoryHeader)) ||
          validateObject(response.data.inventoryInformation) ||
          validateObject(response.data.currentAgreement) ||
          validateObject(response.data.inventoryTransferInformation)
        )
      ) {
        setinventoryInformations({
          ...inventoryInformations,
          inventoryHeaderObj: response.data.inventoryHeader,
          inventoryInformationObj: response.data.inventoryInformation,
          currentAgreementObj: response.data.currentAgreement,
          inventoryTransferObj: response.data.inventoryTransferInformation
        })
        console.log('inventoryInformations log', inventoryInformations)
      }
      SetInventoryTransferInPopup(true)
    }
    catch(e){
      SetStoreActionItemId('');
      setloader(false);
      console.log(e)
    }
  }
  const handleDSS = async (sa: any) => {
    setfetchDataLoader(true);
    try {
      const thedate = sa.redirectUrl.split('/')[2];
      const eventNumber = sa.redirectUrl.split('/')[3];

      const payload: any = { storeNumber: sessionStorage.getItem('storeNumber'), appointmentTypes: ['D', 'CO', 'CI'], startDate: thedate, endDate: thedate, appointmentId: eventNumber };
      const response: any = await getDapEvent(payload);
      setfetchDataLoader(false);
      console.log('response', response);
      let appointment: any = null;
      if (response.data) {
        appointment = response.data;
        console.log('appointment', appointment);
        setActionEvent(appointment);
      }
      if (!appointment) {
        seterrorMessagePopupOpen(true);
        setmanageErrMessage("Error in fetching valid event");
      }

    }
    catch (e) {
      setfetchDataLoader(false);
      console.log(e)
    }

  }
  const borderLeftColor = (el: any) => {
    if (el?.storeActionCategory == "AGR") {
      return "3px solid  #2E31BE"
    }
    if (el?.storeActionCategory == "INV") {
      return "3px solid  #FFA61E"
    }
    if (el?.storeActionCategory == "CUS") {
      return "3px solid #DD4F89"
    }
  }
  const bindColorCategory = (el: any) => {
    if (el?.storeActionCategory == "AGR") {
      return "#2E31BE"
    }
    if (el?.storeActionCategory == "INV") {
      return "#FFA61E"
    }
    if (el?.storeActionCategory == "CUS") {
      return "#DD4F89"
    }
    //for payment color code #48C69A
  }
  const handleIconClick = (event, el) => {
    SetMarkAsCompletePopup(true);
    SetStoreActionItemId(el?.storeActionItemId);
    event.stopPropagation();
  };
  const bindMessage = (): any => {
    // debugger;
    console.log('storeActionRes', storeActionRes)
    return storeActionRes?.AlteredVal?.map((el: any, index: any) => {
      const CreatedDate: any = moment(el?.createdDate.split('T')[0]).format('MM/DD/YYYY');
      const shouldShowIcon = el?.storeActionItemId !== '767676';
      return (
        <>
          {el !== undefined && el !== null && el !== '' ?
            <Card style={{
              margin: "0.5%", width: "48%",
              zIndex: 0, textDecoration: 'none', fontSize: 'medium',
              fontWeight: 'bold', color: '#2179FE',
              borderLeft: borderLeftColor(el), display: 'flex',
              position: 'relative'
            }} key={index}>
              <CardContent style={{ width: "100%" }} >
                <a id='StoreActionItemsCard' style={{
                  width: "100%",
                  position: 'relative',
                  display: 'block',
                  padding: '0.5rem1rem',
                  color: '#212529',
                  textDecoration: 'none',
                  // backgroundColor: '#F4F7FF',
                  // border: '1px solid rgba(0, 0, 0, 0.125)',
                  // paddingLeft: "5%",
                  cursor: 'pointer',
                  borderRadius: "3px",
                  boxShadow: "0 0.125rem 0.25rem rgb(0 0 0 / 8%) !important",
                }}
                  onClick={() => handleNavigate(el)}
                >
                  <Grid style={{ display: 'flex', flexDirection: 'column', paddingLeft: 0, marginBottom: 0, borderRadius: '0.25rem' }}>
                    <Grid style={{ width: "100%" }}>
                      <Grid style={{ width: "100% important" }}>
                        <Typography title={el?.description} style={{ width: shouldShowIcon ? '70%' : '100%', float: 'left' }}>
                          {truncateString(el?.description)}
                        </Typography>
                        <Typography style={{ width: shouldShowIcon ? '30%' : '0%', float: 'left' }}>
                          {shouldShowIcon && (
                            <MarkAsCompleteIcon title="Mark as completed" style={{ width: '25px', marginLeft: '115px' }}
                              onClick={(event) => { handleIconClick(event, el) }}
                            >
                            </MarkAsCompleteIcon>
                          )}
                        </Typography>
                      </Grid>
                      <Grid>
                        <Grid className={classes.row}>
                          <Typography style={{ width: "80%", color: bindColorCategory(el) }}>
                            {bindCategory(el)}
                          </Typography>
                          <Typography style={{ color: "#6C86A5" }}>
                            {CreatedDate}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </a>
              </CardContent>
            </Card>
            : null}
        </>
      )
    })
  }
  function ExpandModelCard() {
    return (
      <RACModalCard
        isOpen={expandStore}
        maxWidth='xs'
        closeIcon={false}
        borderRadius="20px"
        dialogOptionalProps={{ open: expandStore, disableBackdropClick: false }}
        onClose={() => setExpandStore(false)}
        // eslint-disable-next-line react/no-children-prop
        children={ExPandStoreActionItem()}
      // title="Validation Message"
      />
    );
  }
  const ExPandStoreActionItem = () => {
    return (
      <Grid
        container
        spacing={1}
        className={`${classes.RACcardHeightModal}`}
      >
        {expandIcon ?
          <Expandicon
            className={classes.RACexpandIconclose}
            data-bs-toggle="modal"
            data-bs-target="#storeActionItem"
            onClick={() => expandStoreActionItem()}
            data-testid="expandSLClose"
          >
          </Expandicon> : null}
        <Grid style={{ width: "100%" }}>
          {expandAPICallLoader ? <Grid className={`${classes.circularLoader} ${classes.textCenter} ${classes.w100}`}>
            <CircularProgress></CircularProgress>
          </Grid> : null}
          {storeActionRes !== undefined && storeActionRes !== null && storeActionRes !== '' && storeActionRes?.AlteredVal !== undefined && storeActionRes?.AlteredVal !== null && storeActionRes?.AlteredVal !== '' && storeActionRes?.AlteredVal?.length !== 0 ?
            bindPopUpMessage()
            : null}
          {/* {bindMessage()} */}
        </Grid>
      </Grid>
    )
  }
  const fetchNextData = async () => {
    // debugger;
    if (hasMore) {
      const existingData: any = storeActionRes;
      sethasMore(true);
      setfetchDataLoader(true);
      setoffset(offset + 1);
      const storeNumber = window.sessionStorage.getItem('storeNumber');
      const payload = {
        storeNum: storeNumber,
        limit: 10,
        offset: offset + 1
      }
      const fetchNextDataRes = await getStoreActionsItem(payload);
      console.log("fetchNextDataRes", fetchNextDataRes)
      if (fetchNextDataRes?.status == 200) {
        setfetchDataLoader(false);
        {
          if (!fetchNextDataRes || !fetchNextDataRes?.data || !fetchNextDataRes?.data?.storeActionDetails) return null
          fetchNextDataRes.data.storeActionDetails = sortStoreActionItemIds(fetchNextDataRes.data.storeActionDetails)
          setStoreActionRes({ ...storeActionRes, originalVal: [...existingData.originalVal, ...fetchNextDataRes?.data?.storeActionDetails], AlteredVal: [...existingData.AlteredVal, ...fetchNextDataRes?.data?.storeActionDetails] })
        }
        if (fetchNextDataRes?.data?.storeActionDetails?.length !== 10) {
          sethasMore(false);
          setNomoreDataOtherReq(true);
        }
      }
    }
  }
  const helperClicked = () => {
    // debugger;
    hideHelper == '' ? setHideHelper('show') : setHideHelper('');
  };
  const outsideHelperClicked = (e: any) => {
    // debugger;
    if (!e.target.dataset.value) {
      setHideHelper('');
    }
    else if (e.target.dataset.value) {
      setAMLoader(true)
      const AlteredArray: any = [];
      console.log("category", e.target.dataset.value)
      const category = e.target.dataset.value
      setStoreFilterDetails({ ...storeFilterDetails, filterDetails: category })
      return storeActionRes?.originalVal?.map((el: any) => {
        if (category == "AGR") {
          if (el?.storeActionCategory == "AGR") {
            AlteredArray.push(el)
          }
        }
        if (category == "INV") {
          if (el?.storeActionCategory == "INV") {
            AlteredArray.push(el)
          }
        }
        if (category == "CUS") {
          if (el?.storeActionCategory == "CUS") {
            AlteredArray.push(el)
          }
        }
        if (category == "ALL") {
          AlteredArray.push(el)
        }
        setStoreActionRes({ ...storeActionRes, AlteredVal: AlteredArray })
        if (AlteredArray == undefined || AlteredArray == '' || AlteredArray == null || AlteredArray?.length == 0) {
          setExpandIcon(false)
        }
        else if (AlteredArray !== undefined && AlteredArray !== '' && AlteredArray !== null && AlteredArray?.length > 0) {
          setExpandIcon(true)
        }

      })
    }

  };
  const errorMessagePopup = () => {
    return (
      <div
        id="initialpayment"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        aria-hidden="true"
      >
        <Grid className={classes.textCenter}>
          <AlertIcon></AlertIcon>
          <Typography className={classes.formLabel}>
            {manageErrMessage}
          </Typography>
        </Grid>
        <Grid
          item
          md={12}
          className={`${classes.mt3} ${classes.pb4} ${classes.textCenter}`}
        >
          <RACButton
            className={classes.mx1}
            variant="contained"
            color="primary"
            onClick={() => {
              seterrorMessagePopupOpen(false);
            }}
          >
            Ok
          </RACButton>
        </Grid>
      </div>
    );
  };
  const somethingWentWrongPopup = () => {
    return (
      <div
        id="initialpayment"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        aria-hidden="true"
      >
        <Grid className={classes.textCenter}>
          <AlertIcon></AlertIcon>

          <Typography className={classes.formLabel}>
            Something went wrong!
          </Typography>
        </Grid>
        <Grid
          item
          md={12}
          className={`${classes.mt3} ${classes.pb4} ${classes.textCenter}`}
        >
          <RACButton
            className={classes.mx1}
            variant="contained"
            color="primary"
            onClick={() => {
              setSomethingWentWrongOpen(false);
            }}
          >
            Ok
          </RACButton>
        </Grid>
      </div>
    );
  };
  const MarkAsCompletePopupFn = () => {
    return (
      <>
        <Grid item md={12}>
          <Grid item md={12} className={classes.textCenter}>
            <Typography className={classes.RACPOPMsg}>
              Are you sure want to Mark this store action item completed?
            </Typography>
          </Grid>
          <Grid
            container
            className={`${classes.justifyContentCenter} ${classes.spacerMT4}`}
          >
            <RACButton
              variant="outlined"
              color="primary"
              className={classes.me2}
              onClick={() => { SetMarkAsCompletePopup(false); SetStoreActionItemId('') }}
            >
              No
            </RACButton>
            <RACButton
              variant="contained"
              color="primary"
              className={`${classes.me2} ${classes.foc}`}
              onClick={() => { manageStoreActionItem() }}
            >
              Yes
            </RACButton>
          </Grid>
        </Grid>
      </>
    );
  };
  const InventortTransferOutPopupFn = () => {
    const popupMsg = (inventoryInformations?.currentAgreementObj) && (inventoryInformations.currentAgreementObj.customerName != undefined) ? `An eCommerce Agreement was created for Customer ${inventoryInformations.currentAgreementObj.customerName} with inventory 
    #${inventoryInformations.inventoryHeaderObj.itemNumber} from your store. The inventory was auto 
    transferred to store ${(inventoryInformations?.inventoryTransferObj && inventoryInformations?.inventoryTransferObj.receivingStoreNumber) ? inventoryInformations?.inventoryTransferObj.receivingStoreNumber : ''} to pick-up the inventory.` : `A Sales lead was created with inventory #${inventoryInformations.inventoryHeaderObj.itemNumber} from your store. The inventory was auto transferred to store ${(inventoryInformations?.inventoryTransferObj && inventoryInformations?.inventoryTransferObj.receivingStoreNumber) ? inventoryInformations?.inventoryTransferObj.receivingStoreNumber : ''} to pick-up the inventory.`;

    return (
      <>
        <Grid item md={12}>
          <Grid item md={12} className={classes.textCenter}>
            <Typography className={classes.RACPOPMsg}>
              {popupMsg}
            </Typography>
          </Grid>
          <Grid
            container
            className={`${classes.justifyContentCenter} ${classes.spacerMT4}`}
          >
            <RACButton
              variant="outlined"
              color="primary"
              className={classes.me2}
              onClick={() => { SetInventoryTransferOutPopup(false); SetStoreActionItemId('') }}
            >
              ok
            </RACButton>
            <RACButton
              variant="contained"
              color="primary"
              className={`${classes.me2} ${classes.foc}`}
              onClick={() => { printHTML(document.getElementById('transferOutFormInnerHtml')); manageStoreActionItem();
                html2pdf()
                .from(document.getElementById('transferOutFormInnerHtml'))
                .outputPdf('blob')
                .then((pdfBlob) => {
                  const CLreader: any = new FileReader();
                  console.log('Reader Transfer Out Form', CLreader);
                  CLreader.onloadend = async () => {
                    console.log('after Reader Transfer Out Form', CLreader);
                    const b64TransferOutForm = CLreader?.result.split(',')[1];
                    console.log('b64TransferOutForm', b64TransferOutForm);
                    makeUploadDocument(inventoryInformations?.inventoryInformationObj.inventoryId, b64TransferOutForm);
                  }
                  CLreader.readAsDataURL(pdfBlob);
                });
                SetInventoryTransferOutPopup(false)
              }}
            >
              Print
            </RACButton>
          </Grid>
        </Grid>
      </>
    );
  };
  const InventortTransferInPopupFn = () => {
    const popupMsg = (inventoryInformations?.currentAgreementObj) && (inventoryInformations.currentAgreementObj.customerName != undefined) ? `eCommerce Customer ${inventoryInformations.currentAgreementObj.customerName} with inventory 
    #${inventoryInformations.inventoryHeaderObj.itemNumber} was auto transferred to your store. Please make arrangements to pick-up the inventory located at store ${(inventoryInformations?.inventoryTransferObj && inventoryInformations?.inventoryTransferObj.transferringStoreNumber) ? inventoryInformations?.inventoryTransferObj.transferringStoreNumber : ''}.` : `A Sales lead was created with inventory #${inventoryInformations.inventoryHeaderObj.itemNumber} 
    was auto transferred to your store. Please make arrangements to pick-up the inventory located at store ${(inventoryInformations?.inventoryTransferObj && inventoryInformations?.inventoryTransferObj.transferringStoreNumber) ? inventoryInformations?.inventoryTransferObj.transferringStoreNumber : ''}.`;
    return (
      <>
        <Grid item md={12}>
          <Grid item md={12} className={classes.textCenter}>
            <Typography className={classes.RACPOPMsg}>
              {popupMsg}
            </Typography>
          </Grid>
          <Grid
            container
            className={`${classes.justifyContentCenter} ${classes.spacerMT4}`}
          >
            <RACButton
              variant="outlined"
              color="primary"
              className={classes.me2}
              onClick={() => { SetInventoryTransferInPopup(false); SetStoreActionItemId('') }}
            >
              ok
            </RACButton>
            <RACButton
              variant="contained"
              color="primary"
              className={`${classes.me2} ${classes.foc}`}
              onClick={() => { printHTML(document.getElementById('transferInFormInnerHtml')); manageStoreActionItem();
                html2pdf()
                .from(document.getElementById('transferInFormInnerHtml'))
                .outputPdf('blob')
                .then((pdfBlob) => {
                  const CLreader: any = new FileReader();
                  console.log('Reader Transfer IN Form', CLreader);
                  CLreader.onloadend = async () => {
                    console.log('after Reader Transfer In Form', CLreader);
                    const b64TransferInForm = CLreader?.result.split(',')[1];
                    console.log('b64TransferInForm', b64TransferInForm);
                    makeUploadDocument(inventoryInformations?.inventoryInformationObj.inventoryId, null, b64TransferInForm);
                  }
                  CLreader.readAsDataURL(pdfBlob);
                });
                SetInventoryTransferInPopup(false);
              }}
            >
              Print
            </RACButton>
          </Grid>
        </Grid>
      </>
    );
  };
  const makeUploadDocument = async (inventoryId: any, transferOutForm?: any, transferInForm?: any) => {
    
    console.log('inventoryId inside makeUploadDocument fn', inventoryId)

    const uploadDeliveryReceiptPayload = {
      action: 4,
      inventoryId: inventoryId,
      documents: [
        {
          documentType: transferOutForm ? 'TOF' : 'TIF',
          file: transferOutForm ? transferOutForm : transferInForm
        }
      ]
    };

    await GenerateReceipt(uploadDeliveryReceiptPayload);
  };
  return (
    <>
      <ExpandModelCard />
      {loader ? (
        <Grid className={classes.masterLoader} style={{ zIndex: '9999' }}>
          <Grid className={classes.Loader}>
            <CircularProgress />
          </Grid>
        </Grid>
      ) : null}
      <Grid item className={`${classes.px2} ${classes.w100}`} >
        <Card
          className={`${classes.w100} ${classes.RACcardsales}  ${classes.card}  ${classes.cardHeight}`}
        >
          <Grid container style={{ padding: "15px 15px" }}>
            <Grid item style={{ width: "93%" }}>
              <Typography
                variant="h6"
                className={`${classes.title} ${classes.spaceTitle}`}
              >
                Store Action Items
                {expandIcon && (!Status) ?
                  <Expandicon
                    className={classes.RACexpandIcon}
                    data-bs-toggle="modal"
                    data-bs-target="#storeActionItem"
                    onClick={() => expandStoreActionItem()}
                    data-testid="expandonlineClose"
                  ></Expandicon> : null}
              </Typography>
            </Grid>
            <Grid className={`${classes.positionRelative}`}>
              <RacFilter onClick={helperClicked}></RacFilter>
              <OutsideClickHandler
                onOutsideClick={outsideHelperClicked}
              >
                <Card
                  className={hideHelper === 'show' ? classes.racGlobalSearchShow : classes.racGlobalSearchHide}
                >
                  <CardContent>
                    <Grid item className={classes.statusDropdownWidthForMultiSelect}
                    >
                      <RACSelect
                        options={options.advancedFilter} // Options to display in the dropdown
                        classes={{ formControl: classes.multiSelect }}
                        defaultValue={storeFilterDetails.filterDetails}
                        onChange={(e: any) => handleFilter(e)}
                      />
                    </Grid>
                  </CardContent>
                </Card>
              </OutsideClickHandler>
            </Grid>
          </Grid>
          <CardContent
            className={`${autoScroll ? classes.fixedheadertableonline1 : null}`}
          // className={classes.fixedheadertableonline1} 
          >
            <Grid style={{ width: "100%" }} className={classes.row} >
              {
                Status ?
                  <Grid className={classes.textcenterNorecords}>
                    <Grid>
                      <AlertIcon className={classes.alerticon}>
                        something went Wrong{' '}
                      </AlertIcon>
                    </Grid>
                    something went Wrong!
                  </Grid> :
                  noRecord ?
                    <><Grid style={{ marginLeft: "35%", marginTop: "1%" }}>
                      <NoRecord style={{ width: '38px', height: '55px', marginLeft: "222%", marginTop: "60px" }}></NoRecord>
                    </Grid><Grid className={classes.row} style={{ marginTop: "3.5%" }}>
                        <Typography style={{ marginTop: "94.5px", color: '#A5A6A6' }}>
                          No Records Found
                        </Typography>
                      </Grid></>
                    :
                    (storeActionRes !== undefined && storeActionRes !== null && storeActionRes !== ''
                      && storeActionRes?.AlteredVal !== undefined && storeActionRes?.AlteredVal !== null && storeActionRes?.AlteredVal !== '' && storeActionRes?.AlteredVal?.length !== 0)
                      ?
                      storeActionRes?.originalVal?.length > 9 ? (
                        <Grid style={{ width: "100%" }}>
                          <InfiniteScroll
                            next={fetchNextData}
                            dataLength={storeActionRes?.originalVal?.length}
                            hasMore={hasMore}
                            height={185}
                            style={{ width: "100%", display: "flex", flexWrap: "wrap" }}
                            loader={
                              fetchDataLoader ? (
                                <Grid className={`${classes.circularLoader} ${classes.textCenter} ${classes.w100}`}>
                                  <CircularProgress></CircularProgress>
                                </Grid>
                              ) : NomoreDataOtherReq ? (
                                <Grid className={`${classes.circularLoader} ${classes.textCenter} ${classes.w100}`}>
                                  <Typography>No More Data</Typography>
                                </Grid>
                              ) : null
                            }
                          >
                            <>
                              {/* <Grid className={classes.row} style={{width:"100%",height:"20%"}} > */}
                              {bindMessage()}
                              {/* </Grid> */}
                            </>
                          </InfiniteScroll>
                        </Grid>
                      ) :
                        bindMessage()
                      : !amLoader ? <Grid className={`${classes.circularLoader} ${classes.textCenter} ${classes.w100}`}>
                        <CircularProgress />
                      </Grid> :
                        <><Grid style={{ marginLeft: "35%", marginTop: "1%" }}>
                          <NoRecord style={{ width: '38px', height: '55px', marginLeft: "222%", marginTop: "60px" }}></NoRecord>
                        </Grid><Grid className={classes.row} style={{ marginTop: "3.5%" }}>
                            <Typography style={{ marginTop: "94.5px", color: '#A5A6A6' }}>
                              No Records Found
                            </Typography>
                          </Grid></>
              }
            </Grid>
          </CardContent>
        </Card>
      </Grid>
      <RACModalCard
        isOpen={openMarkAsCompletePopup}
        onClose={() => { SetMarkAsCompletePopup(false); SetStoreActionItemId('') }}
        maxWidth="xs"
        borderRadius="20px"
        closeIcon={true}
        // eslint-disable-next-line react/no-children-prop
        children={MarkAsCompletePopupFn()}
      />
      <RACModalCard
        isOpen={openInventoryTransferOutPopup}
        onClose={() => { SetInventoryTransferOutPopup(false); SetStoreActionItemId('') }}
        maxWidth="xs"
        borderRadius="20px"
        closeIcon={true}
        title='eCommerce Transfer OUT'
        // eslint-disable-next-line react/no-children-prop
        children={InventortTransferOutPopupFn()}
      />
      <RACModalCard
        isOpen={openInventoryTransferInPopup}
        onClose={() => { SetInventoryTransferInPopup(false); SetStoreActionItemId('') }}
        maxWidth="xs"
        borderRadius="20px"
        closeIcon={true}
        title='eCommerce Transfer IN'
        // eslint-disable-next-line react/no-children-prop
        children={InventortTransferInPopupFn()}
      />
      <RACModalCard
        isOpen={errorMessagePopupOpen}
        closeIcon={false}
        maxWidth="xs"
        borderRadius={modalCardRadius}
      >
        {errorMessagePopup()}
      </RACModalCard>
      <RACModalCard
        isOpen={somethingWentWrongOpen}
        closeIcon={false}
        maxWidth="xs"
        borderRadius={modalCardRadius}
      >
        {somethingWentWrongPopup()}
      </RACModalCard>
      {/* Printing docs */}
      {/* Transfer Out Form start */}
      <div className={classes.hide}>
        <div id="transferOutFormInnerHtml">
          <div style={{ float: 'left', width: '94%', padding: '3%' }}>

            <div style={{ width: '100%', float: 'left', marginBottom: '10px' }}>
              <h2 style={{ float: 'left', margin: '0px' }}>Transfer Out Form</h2>
              <div style={{ float: 'right' }}>{moment().format('ddd,D MMM YYYY, hh:mm A')}</div>
            </div>

            <div style={{ width: '100%', float: 'left', marginBottom: '20px' }}>
              <label style={{ float: 'right' }}>Page: 1</label>
            </div>

            <div style={{ width: '50%', float: 'left', marginBottom: '20px' }}>
              <label style={{ margin: '0px' }}>Transferring Store: {validateObject(storeNumber) ? storeNumber : '-'}</label>
            </div>
            <div style={{ width: '50%', float: 'left', marginBottom: '20px' }}>
              <label style={{ margin: '0px' }}>Receiving Store: {validateObject(inventoryInformations.inventoryTransferObj) && validateObject(inventoryInformations.inventoryTransferObj.receivingStoreNumber) ? inventoryInformations.inventoryTransferObj.receivingStoreNumber : ' - '}</label>
              {/* <label style={{ margin: '0px' }}>Receiving Store: {validateObject(transferState) && transferState != 'Select' ? transferState : validateObject(transfer2Store) && transfer2Store != '' ? transfer2Store : ''}</label> */}
            </div>

            <div style={{ width: '100%', float: 'left', marginBottom: '20px' }}>
              <table style={{ borderCollapse: 'collapse', width: '100%' }}>
                <thead>
                  <tr>
                    <th style={{ borderBottom: '1px solid #000', color: '#000', textAlign: 'left', padding: '7px', fontSize: '14px' }}>Item</th>
                    <th style={{ borderBottom: '1px solid #000', color: '#000', textAlign: 'left', padding: '7px', fontSize: '14px' }}>Model</th>
                    <th style={{ borderBottom: '1px solid #000', color: '#000', textAlign: 'left', padding: '7px', fontSize: '14px' }}>Vendor</th>
                    <th style={{ borderBottom: '1px solid #000', color: '#000', textAlign: 'left', padding: '7px', fontSize: '14px' }}>Brand</th>
                    <th style={{ borderBottom: '1px solid #000', color: '#000', textAlign: 'left', padding: '7px', fontSize: '14px' }}>MFG Serial #</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td style={{ paddingTop: '7px', fontSize: '14px' }}>{validateObject(inventoryInformations.inventoryHeaderObj) && validateObject(inventoryInformations.inventoryHeaderObj.itemNumber) ? inventoryInformations.inventoryHeaderObj.itemNumber : '-'}</td>
                    <td style={{ paddingTop: '7px', fontSize: '14px' }}>{validateObject(inventoryInformations.inventoryInformationObj) && validateObject(inventoryInformations.inventoryInformationObj.modelNumber) ? inventoryInformations.inventoryInformationObj.modelNumber : '-'}</td>
                    <td style={{ paddingTop: '7px', fontSize: '14px' }}>{validateObject(inventoryInformations.inventoryInformationObj) && validateObject(inventoryInformations.inventoryInformationObj.vendor) ? inventoryInformations.inventoryInformationObj.vendor : '-'}</td>
                    <td style={{ paddingTop: '7px', fontSize: '14px' }}>{validateObject(inventoryInformations.inventoryInformationObj) && validateObject(inventoryInformations.inventoryInformationObj.brand) ? inventoryInformations.inventoryInformationObj.brand : '-'}</td>
                    <td style={{ paddingTop: '7px', fontSize: '14px' }}>{validateObject(inventoryInformations.inventoryInformationObj) && validateObject(inventoryInformations.inventoryInformationObj.serialNumber) ? inventoryInformations.inventoryInformationObj.serialNumber : '-'}</td>
                  </tr>
                  <tr />
                </tbody>
              </table>
            </div>
            <div style={{ width: '100%', float: 'left', textAlign: 'center', marginBottom: '70px', marginTop: '70px' }}>
              <div style={{ width: '100%', fontSize: '25px' }}>I Am Sold!</div>
              <div style={{ width: '100%', fontSize: '25px' }}>Please ask to see another</div>
              <div style={{ width: '100%', fontSize: '25px' }}>One just like me!</div>
            </div>
            <div style={{ width: '100%', float: 'left' }}>
              <div style={{ width: '50%', float: 'left' }}>
                <h4>Delivered By</h4>
                <div style={{ marginBottom: '30px', width: '100%', float: 'left' }}>
                  <span style={{ borderBottom: '2px solid #000', width: '85%', float: 'left', marginBottom: '10px' }}></span>
                  <div style={{ width: '100%', float: 'left' }}>Employee Id</div>
                </div>
                <div style={{ marginBottom: '30px', width: '100%', float: 'left' }}>
                  <span style={{ borderBottom: '2px solid #000', width: '85%', float: 'left', marginBottom: '10px' }}></span>
                  <div style={{ width: '100%', float: 'left' }}>Printed Name</div>
                </div>
                <div style={{ marginBottom: '30px', width: '100%', float: 'left' }}>
                  <span style={{ borderBottom: '2px solid #000', width: '85%', float: 'left', marginBottom: '10px' }}></span>
                  <div style={{ width: '100%', float: 'left' }}>Signature</div>
                </div>
              </div>

              <div style={{ width: '50%', float: 'left' }}>
                <h4>Received By</h4>
                <div style={{ width: '70%', float: 'left', marginBottom: '30px' }}>
                  <span style={{ borderBottom: '2px solid #000', width: '85%', float: 'left', marginBottom: '10px' }}></span>
                  <div style={{ width: '100%', float: 'left' }}>Employee Id</div>
                </div>
                <div style={{ width: '30%', float: 'left', marginBottom: '30px' }}>
                  <span style={{ borderBottom: '2px solid #000', width: '70%', float: 'left', marginBottom: '10px' }}></span>
                  <div style={{ width: '100%', float: 'left' }}>Date</div>
                </div>

                <div style={{ marginBottom: '30px', width: '100%', float: 'left' }}>
                  <span style={{ borderBottom: '2px solid #000', width: '91%', float: 'left', marginBottom: '10px' }}></span>
                  <div style={{ width: '100%', float: 'left' }}>Printed Name</div>
                </div>
                <div style={{ marginBottom: '30px', width: '100%', float: 'left' }}>
                  <span style={{ borderBottom: '2px solid #000', width: '91%', float: 'left', marginBottom: '10px' }}></span>
                  <div style={{ width: '100%', float: 'left' }}>Signature</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Transfer Out Form end*/}
      {/* Transfer Receiving Form start */}
      <div className={classes.hide}>
        <div id="transferInFormInnerHtml">
          <div style={{ float: 'left', width: '94%', padding: '3%' }}>

            <div style={{ width: '100%', float: 'left', marginBottom: '10px' }}>
              <h2 style={{ float: 'left', margin: '0px' }}>Transfer Received Form</h2>
              <div style={{ float: 'right' }}>{moment().format('ddd,D MMM YYYY, hh:mm A')}</div>
            </div>

            <div style={{ width: '100%', float: 'left', marginBottom: '20px' }}>
              <label style={{ float: 'right' }}>Page: 1</label>
            </div>

            <div style={{ width: '50%', float: 'left', marginBottom: '20px' }}>
              <label style={{ margin: '0px' }}>Transferring Store: {validateObject(inventoryInformations.inventoryTransferObj) && validateObject(inventoryInformations.inventoryTransferObj.transferringStoreNumber) ? inventoryInformations.inventoryTransferObj.transferringStoreNumber : ' - '} (
                {validateObject(inventoryInformations.inventoryTransferObj) && validateObject(inventoryInformations.inventoryTransferObj.transferringStoreCity) ? inventoryInformations.inventoryTransferObj.transferringStoreCity : ''},{validateObject(inventoryInformations.inventoryTransferObj) && validateObject(inventoryInformations.inventoryTransferObj.transferringStoreState) ? inventoryInformations.inventoryTransferObj.transferringStoreState : ''})</label>
            </div>
            <div style={{ width: '50%', float: 'left', marginBottom: '20px' }}>
              <label style={{ margin: '0px' }}>Receiving Store: {validateObject(storeNumber) ? storeNumber : ' - '} (
                {validateObject(inventoryInformations.inventoryTransferObj) && validateObject(inventoryInformations.inventoryTransferObj.receivingStoreCity) ? inventoryInformations.inventoryTransferObj.receivingStoreCity : ''},{validateObject(inventoryInformations.inventoryTransferObj) && validateObject(inventoryInformations.inventoryTransferObj.receivingStoreState) ? inventoryInformations.inventoryTransferObj.receivingStoreState : ''})</label>
              {/* <label style={{ margin: '0px' }}>Receiving Store: {validateObject(transferState) && transferState != 'Select' ? transferState : validateObject(transfer2Store) && transfer2Store != '' ? transfer2Store : ''}</label> */}
            </div>

            <div style={{ width: '100%', float: 'left', marginBottom: '20px' }}>
              <table style={{ borderCollapse: 'collapse', width: '100%' }}>
                <thead>
                  <tr>
                    <th style={{ borderBottom: '1px solid #000', color: '#000', textAlign: 'left', padding: '7px', fontSize: '14px' }}>Item</th>
                    <th style={{ borderBottom: '1px solid #000', color: '#000', textAlign: 'left', padding: '7px', fontSize: '14px' }}>Model</th>
                    <th style={{ borderBottom: '1px solid #000', color: '#000', textAlign: 'left', padding: '7px', fontSize: '14px' }}>Vendor</th>
                    <th style={{ borderBottom: '1px solid #000', color: '#000', textAlign: 'left', padding: '7px', fontSize: '14px' }}>Brand</th>
                    <th style={{ borderBottom: '1px solid #000', color: '#000', textAlign: 'left', padding: '7px', fontSize: '14px' }}>MFG Serial #</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td style={{ paddingTop: '7px', fontSize: '14px' }}>{validateObject(inventoryInformations.inventoryHeaderObj) && validateObject(inventoryInformations.inventoryHeaderObj.itemNumber) ? inventoryInformations.inventoryHeaderObj.itemNumber : '-'}</td>
                    <td style={{ paddingTop: '7px', fontSize: '14px' }}>{validateObject(inventoryInformations.inventoryInformationObj) && validateObject(inventoryInformations.inventoryInformationObj.modelNumber) ? inventoryInformations.inventoryInformationObj.modelNumber : '-'}</td>
                    <td style={{ paddingTop: '7px', fontSize: '14px' }}>{validateObject(inventoryInformations.inventoryInformationObj) && validateObject(inventoryInformations.inventoryInformationObj.vendor) ? inventoryInformations.inventoryInformationObj.vendor : '-'}</td>
                    <td style={{ paddingTop: '7px', fontSize: '14px' }}>{validateObject(inventoryInformations.inventoryInformationObj) && validateObject(inventoryInformations.inventoryInformationObj.brand) ? inventoryInformations.inventoryInformationObj.brand : '-'}</td>
                    <td style={{ paddingTop: '7px', fontSize: '14px' }}>{validateObject(inventoryInformations.inventoryInformationObj) && validateObject(inventoryInformations.inventoryInformationObj.serialNumber) ? inventoryInformations.inventoryInformationObj.serialNumber : '-'}</td>
                  </tr>
                  <tr />
                </tbody>
              </table>
            </div>
            <div style={{ width: '100%', float: 'left', textAlign: 'center', marginBottom: '70px', marginTop: '70px' }}>
              <div style={{ width: '100%', fontSize: '25px' }}>I Am Sold!</div>
              <div style={{ width: '100%', fontSize: '25px' }}>Please ask to see another</div>
              <div style={{ width: '100%', fontSize: '25px' }}>One just like me!</div>
            </div>
            <div style={{ width: '100%', float: 'left' }}>
              <div style={{ width: '50%', float: 'left' }}>
                <h4>Delivered By</h4>
                <div style={{ marginBottom: '30px', width: '100%', float: 'left' }}>
                  <span style={{ borderBottom: '2px solid #000', width: '85%', float: 'left', marginBottom: '10px' }}></span>
                  <div style={{ width: '100%', float: 'left' }}>Employee Id</div>
                </div>
                <div style={{ marginBottom: '30px', width: '100%', float: 'left' }}>
                  <span style={{ borderBottom: '2px solid #000', width: '85%', float: 'left', marginBottom: '10px' }}></span>
                  <div style={{ width: '100%', float: 'left' }}>Printed Name</div>
                </div>
                <div style={{ marginBottom: '30px', width: '100%', float: 'left' }}>
                  <span style={{ borderBottom: '2px solid #000', width: '85%', float: 'left', marginBottom: '10px' }}></span>
                  <div style={{ width: '100%', float: 'left' }}>Signature</div>
                </div>
              </div>

              <div style={{ width: '50%', float: 'left' }}>
                <h4>Received By</h4>
                <div style={{ width: '70%', float: 'left', marginBottom: '30px' }}>
                  <span style={{ borderBottom: '2px solid #000', width: '85%', float: 'left', marginBottom: '10px' }}></span>
                  <div style={{ width: '100%', float: 'left' }}>Employee Id</div>
                </div>
                <div style={{ width: '30%', float: 'left', marginBottom: '30px' }}>
                  <span style={{ borderBottom: '2px solid #000', width: '70%', float: 'left', marginBottom: '10px' }}></span>
                  <div style={{ width: '100%', float: 'left' }}>Date</div>
                </div>

                <div style={{ marginBottom: '30px', width: '100%', float: 'left' }}>
                  <span style={{ borderBottom: '2px solid #000', width: '91%', float: 'left', marginBottom: '10px' }}></span>
                  <div style={{ width: '100%', float: 'left' }}>Printed Name</div>
                </div>
                <div style={{ marginBottom: '30px', width: '100%', float: 'left' }}>
                  <span style={{ borderBottom: '2px solid #000', width: '91%', float: 'left', marginBottom: '10px' }}></span>
                  <div style={{ width: '100%', float: 'left' }}>Signature</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Transfer Receiving Form end*/}
    </>
  );
}
